define("discourse/plugins/discourse-assign/discourse/initializers/assignment-list-actions", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-assign/discourse/components/assigned-topic-list-column", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _pluginApi, _assignedTopicListColumn, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ASSIGN_LIST_ROUTES = ["userActivity.assigned", "group.assigned.show"];
  const AssignActionsCell = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <td class="assign-topic-buttons">
      <AssignedTopicListColumn @topic={{@topic}} />
    </td>
  
  */
  {
    "id": "dXB8I1in",
    "block": "[[[1,\"\\n  \"],[10,\"td\"],[14,0,\"assign-topic-buttons\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@topic\"],[[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@topic\"],false,[]]",
    "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-assign/discourse/initializers/assignment-list-actions.js",
    "scope": () => [_assignedTopicListColumn.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "assignment-list-actions:AssignActionsCell"));
  var _default = _exports.default = {
    name: "assignment-list-dropdowns",
    initialize(container) {
      const router = container.lookup("service:router");
      (0, _pluginApi.withPluginApi)("1.39.0", api => {
        api.registerValueTransformer("topic-list-columns", _ref => {
          let {
            value: columns
          } = _ref;
          if (ASSIGN_LIST_ROUTES.includes(router.currentRouteName)) {
            columns.add("assign-actions", {
              item: AssignActionsCell,
              after: "activity"
            });
          }
          return columns;
        });
        api.registerValueTransformer("topic-list-item-class", _ref2 => {
          let {
            value: classes
          } = _ref2;
          if (ASSIGN_LIST_ROUTES.includes(router.currentRouteName)) {
            classes.push("assigned-list-item");
          }
          return classes;
        });
      });
    }
  };
});